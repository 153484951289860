<template>
	<div>
		<Header :rightTitle='rightTitle'></Header>
		<div class="inputWrap">
			<div class="inputChild flex align-center">
				<div class="inputChildLeft">+86</div>
				<div class="inputChildRight flex-1">
					<input style="width: 100%;" type="number" placeholder="请输入手机号" />
				</div>
			</div>
			<div class="inputChild flex align-center space-between">
				<div>
					<input type="text" placeholder="请输入验证码" />
				</div>
				<div class="getCodes">
					获取验证码
				</div>
			</div>
			<div class="inputChild flex align-center">
				<div class="inputChildLeft" style="height: 17px;">
					<img src="../../assets/image/login/pwd.png" style="width: 15px;height: 20px;">
				</div>
				<div class="inputChildRight flex-1">
					<input style="width: 100%;" type="password" placeholder="请输入密码" />
				</div>
			</div>
			<div class="inputChild flex align-center">
				<div class="inputChildLeft" style="height: 17px;">
					<img src="../../assets/image/login/pwd.png" style="width: 15px;height: 20px;">
				</div>
				<div class="inputChildRight flex-1">
					<input style="width: 100%;" type="password" placeholder="请再次确认密码" />
				</div>
			</div>
		</div>
		<div class="register">注册</div>
	</div>
</template>

<script>
	import Header from '@/components/header.vue'
	export default {
		name: 'register',
		data() {
			return {
				rightTitle: ''
			}
		},
		created() {
			this.rightTitle = this.$route.query.rightTitle
		},
		components: {
			Header
		},
		mounted() {

		},
		methods: {

		}
	}
</script>

<style scoped>
	.inputWrap {
		margin-top: 24px;
	}

	.inputChild {
		margin: 14px 0.5rem 0 0.5rem;
		height: 58px;
		background: #FFFFFF;
		box-shadow: 0px 2px 10px 0px rgba(223, 223, 223, 0.5);
		border-radius: 8px;
		padding: 0 16px;
		box-sizing: border-box;
	}

	.register {
		margin: 40px 0.8rem 0 0.8rem;
		height: 37px;
		line-height: 37px;
		text-align: center;
		background: linear-gradient(180deg, #006FF4 0%, #003CE5 100%);
		box-shadow: 0px 0px 1px 0px rgba(223, 223, 223, 0.5);
		border-radius: 8px;
		color: #fff;
		font-size: 14px;
		letter-spacing: 4px;
	}


	.inputChildLeft {
		width: 34px;
		color: #BFC0C0;
		font-size: 14px;
		border-right: 1px solid #BFC0C0;
	}

	input::-webkit-input-placeholder {
		color: #BFC0C0;
	}

	input::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #BFC0C0;
	}

	input:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #BFC0C0;
	}

	input:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #BFC0C0;
	}

	.inputChildRight {
		margin-left: 8px;
	}

	.getCodes {
		color: #003CE5;
		font-size: 14px;
	}
</style>
